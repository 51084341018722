import { useState, useEffect, useContext } from "react";
import PlusMinusBtn from "./mini/PlusMinusBtn";
import { useController } from "../context/ControllerContext";
import { multiFetch } from "../utils/multiFetch";
import { useBatch } from "../context/BatchContext";

const List = ({
  isContacted,
  filtered,
}) => {
  const { controller, setController } = useController()
  const { updateBatchProspects } = useBatch()
  const localController = isContacted ? controller.contacted : controller.unContacted
  const [filteredProspects, setFilteredProspects] = useState(filtered);
  
  const handleExpand = id => {
    setFilteredProspects(prevState =>
      prevState.map(prospect =>
        prospect._id === id
          ? { ...prospect, expanded: !prospect.expanded }
          : prospect
      )
    );
  };
  const handleExpandAll = () => {
    let target = isContacted ? 'contacted' : 'unContacted'

    setController(prevState => ({
      ...prevState,
      [target]: {
        ...prevState[target],
        allExpanded: !localController.allExpanded
      }
    }));
  };
  const handleIsAscending = () => {
    let target = isContacted ? 'contacted' : 'unContacted'

     setController(prevState => ({
      ...prevState,
      [target]: {
        ...prevState[target],
        isAscending: !localController.isAscending
      }
    }))
  };
  const interpolate = (str, prospect, timeGreet) => {
    return new Function('prospect', 'timeGreet', `return \`${str}\`;`)(prospect, timeGreet)
  }
  const setEmail = prospect => {
    let timeGreet
    let time = new Date().getHours()
    if(time >= 0) timeGreet = 'Buenos días'
    if(time >= 12) timeGreet = 'Buenas tardes'
    if(time >= 18) timeGreet = 'Buenas noches'

    let chooseHasOrNoWebsite = prospect.hasWebsite ? 'hasWebsiteMail' : 'noWebsiteMail'
    let rawSubject = controller.currentBatch[chooseHasOrNoWebsite].subject
    let rawBody = controller.currentBatch[chooseHasOrNoWebsite].body
    console.log(controller.currentBatch[chooseHasOrNoWebsite])
    let subject = interpolate(rawSubject, prospect, timeGreet)
    let body = interpolate(rawBody, prospect, timeGreet)

    return { subject, body };
  }
  const sendMail = (mail, prospect) => {
    let emails = prospect.mail
    let phones = prospect.phone
    let facebook = prospect.facebook ? prospect.facebook : null
    let instagram = prospect.instagram ? prospect.instagram : null

    navigator.clipboard.writeText(mail.body)
    console.log(mail)

    phones.forEach(phone => {
      if(phone){
        window.open(`https://wa.me/+506${phone}`, '_blank');
      }
    })

    emails.forEach(email => {
      if(email){
        window.open(`mailto:${email}?subject=${mail.subject}`, '_blank');
      }
    })

    if(facebook){
      window.open(`https://m.me/${facebook}`, '_blank');
    }

    if(instagram){
      window.open(`https://ig.me/m/${instagram}`, '_blank');
    }

  }
  const handleContact = async id => {
    let prospect = filteredProspects.find(p => p._id === id)
    let mail = setEmail(prospect)
    let body = {
      batchId: controller.currentBatch._id,
      prospectId: prospect._id
    }
    await multiFetch('/contacted', body, 'PUT')

    updateBatchProspects({
      ...controller.currentBatch, 
      prospects: controller.currentBatch.prospects.map(p => (
        p._id === prospect._id
        ? {...p, contacted: !p.contacted}
        : p
      ))
    })
    sendMail(mail, prospect)
  }
  const handleDelete = async id => {
    let body = {
      batchId: controller.currentBatch._id,
      prospectId: id
    }
    await multiFetch('/delete-prospect', body, 'DELETE')
    updateBatchProspects({
      ...controller.currentBatch, 
      prospects: controller.currentBatch.prospects.filter(p =>
        p._id !== id
      )
    })
  }
  
  //1. Se asegura que cada cambio de FILTERED se refleje en FILTEREDPROSPECTS
  //2. Asegura que FILTEREDPROSPECTS' expanded value siempre sea el indicado
  useEffect(() => {
    setFilteredProspects( 
      filtered.map(prospect => (
        {...prospect, expanded: localController.allExpanded}  
      ))
    )
  }, [filtered])

  return (
    <div className="list">
      <div className="buttons">
        <button className="list-btn" onClick={handleExpandAll}>
          {localController.allExpanded ? 'Collapse All' : 'Expand All'}
        </button>
        <button className="list-btn" onClick={handleIsAscending}>
          {localController.isAscending ? 'Ascending' : 'Descending'}
        </button>
      </div>
      {filteredProspects.map(prospect => (
        <div className="prospect" key={prospect._id}>
          <header onClick={() => handleExpand(prospect._id)}>
            <PlusMinusBtn
              active={prospect.expanded}
              onClick={() => handleExpand(prospect._id)}
            />
            <h2>{prospect.name}</h2>
          </header>
          <div
            className="info"
            style={{ display: prospect.expanded ? 'flex' : 'none' }}
          >
            <span>{prospect.mail.join(' ')}</span>
            <span>{prospect.phone.join(' ')}</span>
            <span>{prospect.facebook}</span>
            <span>{prospect.instagram}</span>
            <span>{prospect.desc}</span>
            <span>{prospect.hasWebsite ? 'HAS WEBSITE' : 'DOESN\'T HAVE WEBSITE'}</span>
            <span>{prospect.indirectContact ? 'INDIRECT' : 'DIRECT'}</span>
            <div className="prospect-buttons">
              <button onClick={ e => handleContact(prospect._id) }>Contact</button>
              <button onClick={ e => handleDelete(prospect._id) }>Delete</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default List;