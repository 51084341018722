import { useController } from "../context/ControllerContext";

const Switch = () => {
  const { controller, setController } = useController()

  const toggleIsInput = () => {
    setController(prevState => (
      {...prevState, isInput: !prevState.isInput}
    ))
  }

  return (
    <div className="switch">
          <span className={ controller && controller.isInput ? 'selected' : ''}
            onClick={ toggleIsInput }
          >Input</span>
          
          <span className={ controller && !controller.isInput ? 'selected' : ''}
            onClick={ toggleIsInput }
          >Output</span>
        </div>
  );
}
 
export default Switch;