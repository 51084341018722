import '../styles/ClientList.css'
import List from "../components/List";
import { useController } from '../context/ControllerContext';

const ClientList = () => {
  const { controller } = useController();
  if(controller.isInput || !controller.currentBatch) return
  const prospects = controller.currentBatch.prospects
  let unContacted = prospects.filter(prospect => !prospect.contacted)
  let contacted = prospects.filter(prospect => prospect.contacted)
  let orderedUnContacted = controller.unContacted.isAscending ? unContacted : unContacted.reverse()
  let orderedContacted = controller.contacted.isAscending ? contacted : contacted.reverse()

  return (
    <div className="wrapper">
      <List 
        isContacted={ false }
        filtered={ prospects && orderedUnContacted } 
      />

      <List 
        isContacted={ true }
        filtered={ prospects && orderedContacted } 
      />    
    </div>
  );
}
 
export default ClientList;


/*

probablemente el codigo tiene un bug con el tema de expanded

probablemente cuando se trigguerea setAllExpanded, eso hace que los useEffects reviertan el effecto

estoy actualizando filteredPros, pero despues vuelvo a meter los prospects originales sin expandir

*/


