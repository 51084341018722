import '../../styles/ClientList.css'

const PlusMinusBtn = props => {    
  let { active } = props

  return (
    <svg className='plus-minus' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line style={{ pointerEvents: 'none' }} x1="2" y1="16" x2="30" y2="16" stroke="white" strokeWidth="4" strokeLinecap="round"/>
      { !active && <line style={{ pointerEvents: 'none' }} x1="16" y1="2" x2="16" y2="30" stroke="white" strokeWidth="4" strokeLinecap="round"/>}
    </svg>
  );
}
 
export default PlusMinusBtn;