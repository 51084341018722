import { createContext, useContext, useState, useEffect } from "react";
import { multiFetch } from "../utils/multiFetch";

const ControllerContext = createContext();

export const useController = () => useContext(ControllerContext);

export const ControllerProvider = ({ children }) => {
  const [controller, setController] = useState({
    isInput: false,
    currentBatch: null,
    unContacted: {
      isAscending: true,
      allExpanded: false,
    },
    contacted: {
      isAscending: true,
      allExpanded: false,
    }
  });

  const setCurrentBatch = batch => {
    setController(prevState => (
      {...prevState, currentBatch: batch}
    ))
  }

  //get controler's state from DB
  useEffect(() => {
    multiFetch('/controller')
    .then((data) => {
      setController(data)
    })
  }, [])
  //listen for global changes with controller
  useEffect(() => {
    multiFetch('/controller', controller, 'PUT')
  }, [controller])

  return (
    <ControllerContext.Provider value={{ controller, setController, setCurrentBatch }}>
      {children}
    </ControllerContext.Provider>
  );
};
