import { useEffect, useState } from 'react'
import Select from './Select'
import Arrow from './mini/Arrow'
import '../styles/Options.css'
import { useBatch } from '../context/BatchContext'
import { useController } from '../context/ControllerContext'
import InputOptions from './InputOptions'
import ProspectMail from './ProspectMail'

const Options = () => {
  const { controller, setCurrentBatch } = useController()
  const { batches, addBatch } = useBatch()
  const [formatedOptions, setFormatedOptions] = useState(null)
  const [isActiveOptions, setIsActiveOptions] = useState(false)
  const [batchName, setBatchName] = useState('')

  const formatOptions = () => {
    let formated = batches.map(batch => (
      {
        name: batch.name,
        id: String(batch._id)
      }
    ))

    setFormatedOptions(formated)
  }
  const handleChange = selectedOption => {
    let currentBatch = batches.find(batch => batch._id === selectedOption.id)
    setCurrentBatch(currentBatch)
  }

  const postBatch = e => {
    e.preventDefault()
    addBatch(batchName)
  }

  useEffect(() => {
    if(batches)
      formatOptions()
  }, [batches])

  return (
    <>
      {isActiveOptions && 
        <div className="options-wrapper">
          <Arrow onClick={ () => setIsActiveOptions(!isActiveOptions) } />
          <div className="options">
            { formatedOptions && 
              <Select 
                head={ controller.currentBatch }
                selectOptions={ formatedOptions }
                handleChange={ change => handleChange(change) } 
              />
            }
            <form
              className='options-form'
            >
              <input 
                type="text"
                onChange={ (e) => setBatchName(e.target.value) }
                placeholder='Batch Name'
              />
              <button
                onClick={ postBatch }
              >
              Create</button>
            </form>
            { controller.isInput && <InputOptions />}
            { !controller.isInput && <ProspectMail />}
          </div>
        </div>
      }
      {!isActiveOptions && 
      <div className="options-btn-wrapper">
        <span
          className="options-btn"
          onClick={ () => setIsActiveOptions(!isActiveOptions) }
        >
          ...
        </span>
      </div>
      }
    </>
  );
}
 
export default Options;