import '../styles/ClientForm.css'
import { multiFetch } from '../utils/multiFetch'
import { useBatch } from '../context/BatchContext'
import { useController } from '../context/ControllerContext'
import { useRef, useState } from 'react'

const ClientForm = () => {
  const { controller, setCurrentBatch } = useController()
  const { updateBatchProspects } = useBatch()
  
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [phone, setPhone] = useState('')
  const [facebook, setFacebook] = useState('')
  const [instagram, setInstagram] = useState('')
  const [hasWebsite, setHasWebsite] = useState(false)
  const [indirectContact, setIndirectContact] = useState(false)
  const [desc, setDesc] = useState('')
  let inputOptions = controller.inputOptions

  let nameInputRef = useRef(null)

  const postProspect = async (formatedEmail, formatedPhone) => {
    let batchId = controller.currentBatch._id
    let prospectData = { name, 
      mail: formatedEmail, 
      phone: formatedPhone, 
      facebook, 
      instagram, 
      hasWebsite, 
      indirectContact, 
      desc, 
      contacted: false, 
      expanded: false 
    }
    
    let url = `/post-prospect/${batchId}`
    let updatedBatch = await multiFetch(url, prospectData, 'POST')

    updateBatchProspects(updatedBatch)
    setCurrentBatch(updatedBatch)
  }
  const clearForm = e => {
    e.preventDefault()
    setName('')
    setMail('')
    setPhone('')
    setFacebook('')
    setInstagram('')
    setHasWebsite(true)
    setIndirectContact(false)
    setDesc('')
  }
  const formatEntree = (entree) => {
    let dividedArr = entree.split('/')
    let formatedArr = []

    dividedArr.forEach(e => {
      e = e.split(' ');
      e = e.join('');
      e = e.split('-');
      e = e.join('');
      formatedArr.push(e)
    })

    return formatedArr
  }
  const handleSubmit = async e => {
    e.preventDefault()
    let formatedPhone = formatEntree(phone, setPhone)
    let formatedEmail = formatEntree(mail, setMail)
    await postProspect(formatedEmail, formatedPhone)
    
    clearForm(e)
    nameInputRef.current.focus()
  }

  if(!controller.isInput) return

  return (
    <form className='prospect-form'>
      {inputOptions.name &&
        <input 
          type="text"
          placeholder="Name"
          value={ name }
          onChange={e => setName(e.target.value)}
          onClick={e => e.target.select() }
          ref={ nameInputRef }
        />
      }
      {inputOptions.mail && 
        <input 
          type="text"
          placeholder="Mail"
          value={ mail }
          onChange={e => setMail(e.target.value)}
          onClick={e => e.target.select() }
        />
      }
      {inputOptions.phone &&
        <input 
          type="text"
          placeholder="Phone"
          value={ phone }
          onChange={e => setPhone(e.target.value)}
          onClick={e => e.target.select() }
        />
      }
      {inputOptions.facebook && 
        <input 
          type="text"
          placeholder="Facebook"
          value={ facebook }
          onChange={e => setFacebook(e.target.value)}
          onClick={e => e.target.select() }
        />
      }
      {inputOptions.instagram &&
        <input 
          type="text"
          placeholder="Instagram"
          value={ instagram }
          onChange={e => setInstagram(e.target.value)}
          onClick={e => e.target.select() }
        />
      }
      {inputOptions.hasWebsite &&
        <div style={{display: 'flex'}}>
          <label style={{textWrap: 'nowrap', marginRight: '50px'}} htmlFor="">Has Website</label>
          <input 
            type="checkbox"
            checked={ hasWebsite }
            onChange={ e => setHasWebsite(e.target.checked) }
            onClick={e => e.target.select() }
          />
        </div>
      }
      {inputOptions.indirectContact &&
        <div style={{display: 'flex'}}>
          <label style={{textWrap: 'nowrap', marginRight: '50px'}} htmlFor="">Indirect Contact</label>
          <input 
            type="checkbox"
            checked={ indirectContact }
            onChange={ e => setIndirectContact(e.target.checked) }
            onClick={e => e.target.select() }
          />
        </div>
      }
      {inputOptions.description && 
        <textarea 
          placeholder="Description"
          value={ desc }
          onChange={e => setDesc(e.target.value)}
          onClick={e => e.target.select() }
        ></textarea>
      }
      <div className="buttons">
        <button onClick={ handleSubmit }>Submit</button>
        <button onClick={ clearForm }>Clear</button>
      </div>
    </form>
  );
}
 
export default ClientForm;