
export const multiFetch = async (url, body, reqType) => {
  const baseUrl = process.env.REACT_APP_BASEURL
  let res = await fetch(baseUrl + url, {
    method: reqType ? reqType : 'GET',
    headers: {'Content-Type': 'application/json'},
    body: body ? JSON.stringify(body) : null
  })
  
  if(!res.ok){
    throw Error('Couldn\'t fetch data')
  }
  
  return await res.json()
}
