import './styles/index.css';
import Options from "./components/Options";
import ClientForm from "./components/ClientForm";
import ClientList from "./components/ClientList";
import Switch from "./components/Switch";
import { BatchProvider } from "./context/BatchContext";
import { ControllerProvider } from "./context/ControllerContext";

function App() {
  return (
    <ControllerProvider>
      <BatchProvider>
        <div className="App">
          <Options />
          <Switch />

          <ClientForm />
          <ClientList />
        </div>
      </BatchProvider> 
    </ControllerProvider>
  );
}

export default App;
