import { useEffect, useState } from "react"
import { useController } from "../context/ControllerContext"

const InputOptions = () => {
  const { controller, setController } = useController()
  const [name, setName] = useState(controller.inputOptions.name)
  const [mail, setMail] = useState(controller.inputOptions.mail)
  const [phone, setPhone] = useState(controller.inputOptions.phone)
  const [facebook, setFacebook] = useState(controller.inputOptions.facebook)
  const [instagram, setInstagram] = useState(controller.inputOptions.instagram)
  const [hasWebsite, setHasWebsite] = useState(controller.inputOptions.hasWebsite)
  const [indirectContact, setIndirectContact] = useState(controller.inputOptions.indirectContact)
  const [description, setDescription] = useState(controller.inputOptions.description)
  const [tracker, setTracker] = useState(controller.inputOptions)
  let [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => {
    if(isFirstRender){
      setIsFirstRender(false)
      return
    }
    setTracker({
      name,
      mail,
      phone,
      facebook,
      instagram,
      hasWebsite,
      indirectContact,
      description
    })
  }, [
      name,
      mail,
      phone,
      facebook,
      instagram,
      hasWebsite,
      indirectContact,
      description
  ])

  useEffect(() => {
    if(isFirstRender){
      setIsFirstRender(false)
      return
    }
    setController(prevState => (
      {...prevState, inputOptions: tracker}
    ))
  }, [tracker])

  return (
    <form className="input-options">
      <div className="option">
        <label>Name</label>
        <input
          type="checkbox"
          checked={ name }
          onChange={ e => setName(e.target.checked)}
        />
      </div>
      <div className="option">
        <label>Mail</label>
        <input
          type="checkbox"
          checked={ mail }
          onChange={ e => setMail(e.target.checked)}
        />
      </div>
      <div className="option">
        <label>Phone</label>
        <input
          type="checkbox"
          checked={ phone }
          onChange={ e => setPhone(e.target.checked)}
        />
      </div>
      <div className="option">
        <label>Facebook</label>
        <input
          type="checkbox"
          checked={ facebook }
          onChange={ e => setFacebook(e.target.checked)}
        />
      </div>
      <div className="option">
        <label>Instagram</label>
        <input
          type="checkbox"
          checked={ instagram }
          onChange={ e => setInstagram(e.target.checked)}
        />
      </div>
      <div className="option">
        <label>Has Website</label>
        <input
          type="checkbox"
          checked={ hasWebsite }
          onChange={ e => setHasWebsite(e.target.checked)}
        />
      </div>
      <div className="option">
        <label>Indirect Contact</label>
        <input
          type="checkbox"
          checked={ indirectContact }
          onChange={ e => setIndirectContact(e.target.checked)}
        />
      </div>
      <div className="option">
        <label>Description</label>
        <input
          type="checkbox"
          checked={ description }
          onChange={ e => setDescription(e.target.checked)}
        />
      </div>
    </form>
  );
}
 
export default InputOptions;