import { useEffect, useRef, useState } from "react";
import DropdownArrow from "./mini/DropdownArrow";
import '../styles/Select.css'

const Select = ({ head, selectOptions, handleChange }) => {
  const [header, setHeader] = useState(head)
  const [options, setOptions] = useState(selectOptions)
  const [isVisible, setIsVisible] = useState(false)
  const selectRef = useRef(null)

  const handleOption = id => {
    let selectedOption = options.find(option => option.id === id)
    setHeader(selectedOption)

    handleChange(selectedOption)
  }

  useEffect(() => {
    setOptions(selectOptions)
    setHeader(head)
  }, [selectOptions, head])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div id="menu-select" className="select-wrapper" >
      <div onClick={ () => setIsVisible(!isVisible)} className="select-element" ref={ selectRef }>
        <span>{ header ? header.name : 'No batches found' }</span>
        <DropdownArrow />
      </div>
      <div className="select-options" style={{ display: isVisible ? 'flex' : 'none' }}>
        {options && options.map(option => {
          return (<span key={ option.id } onClick={ () => handleOption(option.id) } >{ option.name }</span>)
        })}
      </div>
    </div>
  );
}
 
export default Select;